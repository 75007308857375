import {
	GET_SOURCES,
	REMOVE_DOCUMENT_STATUS,
	SET_FILES_FOR_UPLOAD,
	TOGGLE_MODAL,
	FETCH_FILES,
	TOGGLE_LOADER,
	SAVE_META,
	SAVE_META_ERROR,
	UPDATE_CONTENT_STATUSES,
	SET_ACTIVE_FILE_INDEX,
	GET_AUTH_URL,
	GET_AUTH_TOKEN,
	REDEEM_AUTH_CODE,
	CLEAR_APP_AUTH,
	DELETE_TOKEN,
	TOGGLE_UPLOAD_TYPE,
	TOGGLE_EXTRACT_IMAGES,
	TOGGLE_CLASS_MODAL,
	CLEAR_LAST_SAVED,
	CREATE_SCHEMA,
	CREATE_SCHEMA_ERROR,
	ASSIGN_SCHEMA,
	GET_SCHEMAS,
	CLEAR_SCHEMA_STATUSES,
	CLASSIFY_FILES,
	CLASSIFY_FILES_PENDING,
	CLASSIFY_FILES_ERROR,
	SAVE_META_PENDING,
	SET_UPLOAD_DONE
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case SET_UPLOAD_DONE:
			return {
				...state,
				uploadDone: action.payload.status,
				...action.payload
			};
		case GET_SOURCES:
			return {
				...state,
				sources: action.payload
			};
		case GET_SCHEMAS:
			return {
				...state,
				schemas: action.payload
			};
		case REMOVE_DOCUMENT_STATUS:
			return {
				...state,
				statuses: { ...state.statuses, [action.payload]: null }
			};
		case SET_FILES_FOR_UPLOAD:
			return {
				...state,
				filesForUpload: action.payload,
				spreadsheetMode: Boolean(action.payload.find(file => file.type === 'text/csv'))
			};
		case CLEAR_LAST_SAVED:
			return {
				...state,
				saved: null
			};
		case TOGGLE_MODAL:
			return {
				...state,
				modalType: action.payload,
				progress: action.payload ? state.progress : {},
				filesForUpload: action.payload ? state.filesForUpload : [],
				activeIndex: null
			};
		case TOGGLE_CLASS_MODAL:
			return {
				...state,
				showClassModal: action.payload.isOpen,
				classModalId: action.payload.id
			};
		case TOGGLE_UPLOAD_TYPE:
			return {
				...state,
				uploadType: action.payload
			};
		case TOGGLE_EXTRACT_IMAGES:
			return {
				...state,
				extractImages: action.payload
			};
		case FETCH_FILES:
			return {
				...state,
				files: action.payload
			};
		case CLASSIFY_FILES:
			return {
				...state,
				filesClassified: true
			};
		case CLASSIFY_FILES_PENDING:
			return {
				...state,
				filesClassified: false,
				error: null
			};
		case CLASSIFY_FILES_ERROR:
			return {
				...state,
				filesClassified: false,
				error: action.payload
			};
		case UPDATE_CONTENT_STATUSES:
			return {
				...state,
				files: state.files.map(
					file => action.payload.find(newFile => newFile.id === file.id) || file
				)
			};

		case TOGGLE_LOADER:
			return {
				...state,
				isLoading: action.payload
			};
		case SAVE_META_PENDING:
			return {
				...state,
				docError: null,
				saved: null
			};
		case SAVE_META:
			return {
				...state,
				saved: action.payload
			};
		case SAVE_META_ERROR:
			return {
				...state,
				docError: action.payload
			};
		case CREATE_SCHEMA:
			return {
				...state,
				schemaCreateStatus: action?.payload?.status,
				createdSchemaId: action.payload?.data?.document_schema?.doc_schema_id
			};
		case CREATE_SCHEMA_ERROR:
			return {
				...state,
				error: action.payload
			};
		case ASSIGN_SCHEMA:
			return {
				...state,
				schemaAssignStatus: action.payload
			};
		case SET_ACTIVE_FILE_INDEX:
			return {
				...state,
				activeIndex: action.payload
			};
		case GET_AUTH_URL:
			return {
				...state,
				authUrl: action.payload
			};
		case GET_AUTH_TOKEN:
			return {
				...state,
				accessToken: action.payload?.data?.access_token,
				tenant: action.payload?.data?.tenant,
				user: action.payload?.data?.email,
				tokenStatus: action.payload?.status
			};
		case REDEEM_AUTH_CODE:
			return {
				...state,
				oauthStatus: action.payload
			};
		case CLEAR_APP_AUTH:
			return {
				...state,
				oauthStatus: null,
				accessToken: null,
				tokenStatus: null,
				authUrl: null,
				deletionTokenStatus: null
			};
		case CLEAR_SCHEMA_STATUSES:
			return {
				...state,
				schemaCreateStatus: null,
				schemaAssignStatus: null
			};
		case DELETE_TOKEN:
			return {
				...state,
				deletionTokenStatus: action.payload
			};
		default:
			return state;
	}
}
