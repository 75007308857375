import { SET_CONNECTION_STATUS, SET_ERROR } from '../actions/types';

const INITIAL_STATE = { isOnline: true };

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_CONNECTION_STATUS: {
			return {
				...state,
				isOnline: action.payload
			};
		}
		case SET_ERROR: {
			return {
				...state,
				...action.payload
			};
		}
		default:
			return state;
	}
}
