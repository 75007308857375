import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

// Define an async thunk action to fetch data
export const createFolder = createAsyncThunk(
	'folder/createFolder',
	async (data, { rejectWithValue }) => {
		const { folderName, folderId } = data;

		try {
			const response = await API.post(CONFIGURATION.AWS_API_NAME, `/content/folder`, {
				response: true,
				body: {
					folder_name: folderName,
					parent_folder_id: folderId
				}
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const editFolder = createAsyncThunk(
	'folder/editFolder',
	async ({ folderName, folderId }, { rejectWithValue }) => {
		try {
			await API.put(CONFIGURATION.AWS_API_NAME, `/content/folder/${folderId}`, {
				response: true,
				body: {
					folder_name: folderName
				}
			});
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const deleteFolder = createAsyncThunk('folder/deleteFolder', async folderId => {
	const response = await API.del(CONFIGURATION.AWS_API_NAME, `/content/folder/${folderId}`, {
		response: true
	});
	return response;
});

export const getFolders = createAsyncThunk('folder/getFolders', async id => {
	const body = id ? { queryStringParameters: { parent_folder_id: id } } : {};
	const response = await API.get(CONFIGURATION.AWS_API_NAME, `/content/list-all-folders`, {
		response: true,
		body
	});
	return response.data;
});

export const getFolderContent = createAsyncThunk('folder/getFolderContent', async id => {
	const body = id ? { queryStringParameters: { folder_id: id } } : {};
	const response = await API.get(CONFIGURATION.AWS_API_NAME, `/content/folder-contents`, {
		response: true,
		...body
	});
	return response.data;
});

export const getFolderContentStatus = createAsyncThunk(
	'folder/getFolderContentStatus',
	async id => {
		const body = id ? { queryStringParameters: { folder_id: id } } : {};
		const response = await API.get(CONFIGURATION.AWS_API_NAME, `/content/folder-contents`, {
			response: true,
			...body
		});
		return response.data;
	}
);

export const indexFiles = createAsyncThunk('folder/indexFiles', async ({ data, extractImages }) => {
	console.log('index files folder', data, extractImages);
	const requestData = {
		response: true,
		body: { document_ids: data.map(item => item.id), extract_images: extractImages }
	};
	const response = await API.post(CONFIGURATION.AWS_API_NAME, '/content/index-docs', requestData);

	return response.data?.docs_metadata;
});

export const moveFiles = createAsyncThunk(
	'folder/moveFiles',
	async ({ destinationId, filesToMove }, { rejectWithValue }) => {
		const requestData = {
			response: true,
			body: { target_folder_id: destinationId, file_ids: filesToMove }
		};
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				'/content/move-files',
				requestData
			);
			return response;
		} catch (error) {
			return rejectWithValue(error.response?.data?.detail);
		}
	}
);

// Define a slice to manage the data state
const dataSlice = createSlice({
	name: 'folders',
	initialState: {
		data: null,
		loading: false,
		error: null,
		folderDeleted: false,
		folderCreated: false,
		folderEdited: false
	},
	reducers: {
		// Define a reducer to manage the state when the data is fetched
		updateContent: (state, action) => {
			state.folderContent = action.payload;
		}
	},

	extraReducers: builder => {
		builder
			.addCase(createFolder.pending, state => {
				state.loading = true;
				state.error = null;
				state.folderCreated = false;
				state.folder = null;
			})
			.addCase(createFolder.fulfilled, (state, action) => {
				state.loading = false;
				state.folderCreated = true;
				state.folder = action.payload;
			})
			.addCase(createFolder.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? action.error.message;
			})
			.addCase(editFolder.pending, state => {
				state.loading = true;
				state.error = null;
				state.folderEdited = false;
			})
			.addCase(editFolder.fulfilled, state => {
				state.loading = false;
				state.folderEdited = true;
			})
			.addCase(editFolder.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? action.error.message;
			})
			.addCase(deleteFolder.pending, state => {
				state.loading = true;
				state.error = null;
				state.updateContent = false;
				state.folderDeleted = false;
			})
			.addCase(deleteFolder.fulfilled, state => {
				state.loading = false;
				state.folderDeleted = true;
				state.updateContent = true;
			})
			.addCase(deleteFolder.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getFolders.pending, state => {
				state.error = null;
			})
			.addCase(getFolders.fulfilled, (state, action) => {
				state.folderList = action.payload;
			})
			.addCase(getFolders.rejected, (state, action) => {
				state.error = action.error.message;
			})
			.addCase(getFolderContent.pending, state => {
				state.loadingFolderContent = true;
				state.folderContent = null;
				state.error = null;
			})
			.addCase(getFolderContent.fulfilled, (state, action) => {
				state.loadingFolderContent = false;
				state.folderContent = action.payload;
			})
			.addCase(getFolderContent.rejected, (state, action) => {
				state.loadingFolderContent = false;
				state.error = action.error.message;
			})
			.addCase(getFolderContentStatus.pending, state => {
				state.error = null;
			})
			.addCase(getFolderContentStatus.rejected, (state, action) => {
				state.error = action.error.message;
			})
			.addCase(getFolderContentStatus.fulfilled, (state, action) => {
				state.folderContent = action.payload;
			})
			.addCase(indexFiles.pending, state => {
				state.updateContent = false;
				state.error = null;
				state.loadingFolderContent = true;
			})
			.addCase(indexFiles.rejected, (state, action) => {
				state.error = action.error.message;
				state.loadingFolderContent = false;
			})
			.addCase(indexFiles.fulfilled, state => {
				state.updateContent = true;
				state.loadingFolderContent = false;
			})
			.addCase(moveFiles.pending, state => {
				state.error = null;
				state.filesMoved = false;
				state.loading = true;
			})
			.addCase(moveFiles.rejected, (state, action) => {
				state.error = action.payload ?? action.error.message;
				state.loading = false;
			})
			.addCase(moveFiles.fulfilled, state => {
				state.filesMoved = true;
				state.loading = false;
			});
	}
});

export const { updateContent } = dataSlice.actions;
// Export the reducer
export default dataSlice.reducer;
