import { SET_CONNECTION_STATUS, SET_ERROR } from './types';

export const setError = error => async (dispatch, useState) => {
	const { common } = useState();
	dispatch({
		type: SET_ERROR,
		payload: {
			errors: common.errors ? [...common.errors, error] : [error]
		}
	});
};

export const removeError = index => async (dispatch, useState) => {
	const { common } = useState();
	dispatch({
		type: SET_ERROR,
		payload: {
			errors: common.errors.filter((item, idx) => idx !== index)
		}
	});
};

export const setIsOnline = isOnline => async dispatch => {
	dispatch({
		type: SET_CONNECTION_STATUS,
		payload: isOnline
	});
};
